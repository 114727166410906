<template>
  <article
    class="listing-project-block button-circle-1-trigger"
    ref="block"
    :class="{
      'is-hover': isHover,
      'is-alternative': alternative,
    }"
  >
    <nuxt-link
      :to="props.typeProject && props.typeProject === 'selection' && linkSel? 
      localePath({ name: 'works-selection-work-slug', params: { slug: props.item.slug }, query: {sel: linkSel} }) :
        localePath({ name: 'projects-slug', params: { slug: props.item.slug } })
      "
      class="listing-project-block__wrap"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
    >
      <!-- Hesder -->
      <header class="listing-project-block__header">
        <div class="listing-project-block__header__title">
          <!-- Title -->
          <h3 class="text-1">
            <strong>{{ props.item.title }}</strong>
          </h3>

          <!-- Subtitle -->
          <p class="text-1" v-if="props.item.subtitle">
            {{ props.item.subtitle }}
          </p>
        </div>

        <div
          v-if="!alternative"
          class="button-circle-1 is-blue is-small is-diagonal"
        >
          <img
            src="@/assets/img/ico/ico-next-arrow-blue.svg"
            width="20"
            height="20"
            alt="Blue arrow"
          />
        </div>
      </header>
      <!-- Content -->
      <div class="listing-project-block__content is-rounded-wrap m-top-24px">
        <div
          class="listing-project-block__content__preview"
          data-animate="block-image-preview"
        >
          <WidgetPicture
            class="is-cover"
            :image="{ custom: props.item?.imageListing?.responsiveImage }"
            :lazyLoad="true"
            :alt="props.item.title"
          />
        </div>
        <div
          v-if="hasPreview"
          class="listing-project-block__content__video"
          data-animate="block-video-preview"
        >
          <WidgetVideo
            class="is-cover"
            :videoUrl="props.item?.linkPreviewVideoListing"
            :loop="true"
            :forcePlay="!touch && isHover"
            :autoplay="touch"
            :muted="true"
            :preload="'none'"
            :playInViewport="touch"
            @play="onViewportEnter"
            @pause="onViewportLeave"
          />
        </div>

        <!-- Tags -->
        <div
          class="listing-project-block__content__tags text-xxs-1 is-text-uppercase hide-for-touch"
          v-if="
            !alternative &&
            (props.item.industryTag?.length || props.item.technologyTag?.length)
          "
        >
          <ul>
            <li v-for="(tag, index) in props.item.industryTag" :key="index">
              <span data-animate="block-tag">{{ tag.title }}</span>
            </li>
            <li v-for="(tag, index) in props.item.technologyTag" :key="index">
              <span data-animate="block-tag">{{ tag.title }}</span>
            </li>
          </ul>
        </div>

        <!-- Quick View button -->
        <div
          class="listing-project-block__content__quickview hide-for-touch"
          v-if="hasQuickView || alternative"
        >
          <button
            v-if="hasQuickView"
            class="button-round-1 is-transparent"
            data-animate="block-quick-view"
            @click="(event) => onQuickView(event)"
          >
            <span>{{ $t('cta.quickView') }}</span>

            <img
              src="@/assets/img/ico/ico-eye-white.svg"
              width="19"
              height="12"
              alt="White eye"
            />
          </button>

          <div
            v-if="alternative"
            class="button-round-1"
            data-animate="block-quick-view"
          >
            <span>{{ $t('cta.discoverProject') }}</span>
          </div>
        </div>
      </div>
    </nuxt-link>
  </article>
</template>

<script>
export default {
  name: 'ListingProjectBlock',
}
</script>

<script setup>
import { gsap } from 'gsap'

const props = defineProps({
  item: Object,
  alternative: Boolean,
  typeProject: {
    type: String,
    default: 'main'
  },
  linkSel: String
})

const block = ref(null)

const isHover = ref(false)
const touch = ref(false)

const duration = 0.6
const durationFast = 0.4
const ease = 'power2.inOut'

const hasPreview = computed(() => props.item?.linkPreviewVideoListing)
const hasQuickView = computed(() => props.item?.linkQuickViewVideoButton)

const quickview = useState('quickview', () => null)

onMounted(() => {
  touch.value = 'ontouchstart' in window || !!navigator.maxTouchPoints /// !! trasformano 0/1 in Bool
})

const onMouseEnter = () => {
  if (touch.value) return false

  const parent = block.value
  const video = parent?.querySelector('[data-animate="block-video-preview"]')
  const button = parent?.querySelectorAll('[data-animate="block-quick-view"]')
  const tags = parent?.querySelectorAll('[data-animate="block-tag"]')

  isHover.value = true

  if (hasPreview.value) {
    gsap.to(video, { autoAlpha: 1, duration, ease })
  }

  if (hasQuickView.value || props.alternative) {
    gsap.to(button, { y: '0%', duration: durationFast, ease, stagger: 0.1 })
  }
  if (tags?.length) {
    gsap.to(tags, { y: '0%', duration: durationFast, ease, stagger: 0.1 })
  }
}

const onMouseLeave = () => {
  if (touch.value) return false

  const parent = block.value
  const video = parent?.querySelector('[data-animate="block-video-preview"]')
  const button = parent?.querySelectorAll('[data-animate="block-quick-view"]')
  const tags = parent?.querySelectorAll('[data-animate="block-tag"]')

  isHover.value = false

  if (hasPreview.value) {
    gsap.to(video, { autoAlpha: 0, duration, ease })
  }

  if (hasQuickView.value || props.alternative) {
    gsap.to(button, { y: '100%', duration: durationFast, ease, stagger: 0.1 })
  }
  if (tags?.length) {
    gsap.to(tags, { y: '100%', duration: durationFast, ease, stagger: 0.1 })
  }
}

const onViewportEnter = () => {
  const parent = block.value
  const video = parent?.querySelector('[data-animate="block-video-preview"]')

  if (hasPreview.value) {
    gsap.to(video, { autoAlpha: 1, duration, ease })
  }
}

const onViewportLeave = () => {
  const parent = block.value
  const video = parent?.querySelector('[data-animate="block-video-preview"]')

  if (hasPreview.value) {
    gsap.to(video, { autoAlpha: 0, duration, ease })
  }
}

const onQuickView = (event) => {
  event.stopPropagation() // Previene la propagazione del click al link
  event.preventDefault() // Previene l'azione predefinita del bottone

  quickview.value = props.item?.linkQuickViewVideoButton
}
</script>

<style lang="scss">
@import './style.scss';
</style>
